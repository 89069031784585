import { render, staticRenderFns } from "./WithdrawApprove.vue?vue&type=template&id=4bcb1bbd&scoped=true"
import script from "./WithdrawApprove.vue?vue&type=script&lang=js"
export * from "./WithdrawApprove.vue?vue&type=script&lang=js"
import style0 from "./WithdrawApprove.vue?vue&type=style&index=0&id=4bcb1bbd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcb1bbd",
  null
  
)

export default component.exports